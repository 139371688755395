import { FC } from 'react'
import styled from 'styled-components'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  CHURCH_MEMBERSHIP_DESCRIPTION,
  CHURCH_MEMBERSHIP_DURATION_CONTENT,
  CHURCH_MEMBERSHIP_LOCATION_CONTENT,
  CHURCH_MEMBERSHIP_UPCOMING_DATES_CONTENT,
  CLASS_CONTENT,
  CLASS_INFO_LABEL,
  CLASS_OUTLINE_LABEL,
  ClassTopic,
  REQUIRED_MATERIALS_LABEL,
} from './strings'
import Paragraph from '../common/Paragraph'
import { PlanningMailToLink } from '../common/MailToLink'
import DetailsSection from './DetailsSection'

const getTotalSubtopics = (classTopics: ClassTopic[]): number => {
  return classTopics.reduce(
    (total, current) => total + current.subtopics.length,
    0
  )
}

const renderTableRow = (
  rowIndex: number,
  classDateSpan: number,
  classNum: number,
  classDate: string,
  topic: string,
  subtopics: string[]
) => {
  return (
    <>
      <TableRow>
        {rowIndex === 0 && (
          <>
            <TableCell align="center" rowSpan={classDateSpan}>
              {classNum}
            </TableCell>
            <TableCell rowSpan={classDateSpan}>{classDate}</TableCell>
          </>
        )}
        <TableCell rowSpan={subtopics.length + 1}>{topic}</TableCell>
      </TableRow>
      {subtopics.map((subtopic) => (
        <TableRow>
          <TableCell>{subtopic}</TableCell>
        </TableRow>
      ))}
    </>
  )
}

const createClassDetails = (
  classNum: number,
  classDate: string,
  classTopics: ClassTopic[]
) => {
  return (
    <>
      {classTopics.map((item, index) => (
        <>
          {renderTableRow(
            index,
            classTopics.length + getTotalSubtopics(classTopics),
            classNum,
            classDate,
            item.topic,
            item.subtopics
          )}
        </>
      ))}
    </>
  )
}

const BookInfo = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`

const PurchaseInfo = styled.div`
  margin-left: 20px;
`

const ChurchMembership: FC = () => {
  return (
    <>
      <Paragraph>{CHURCH_MEMBERSHIP_DESCRIPTION}</Paragraph>
      <Typography variant="h2" gutterBottom>
        {CLASS_INFO_LABEL}
      </Typography>
      <DetailsSection
        upcomingDates={CHURCH_MEMBERSHIP_UPCOMING_DATES_CONTENT}
        duration={CHURCH_MEMBERSHIP_DURATION_CONTENT}
        location={CHURCH_MEMBERSHIP_LOCATION_CONTENT}
        email={<PlanningMailToLink />}
      />
      <Typography variant="h2" gutterBottom>
        {CLASS_OUTLINE_LABEL}
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableCell>Class</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Topic</TableCell>
            <TableCell>Subtopics</TableCell>
          </TableHead>
          <TableBody>
            {CLASS_CONTENT.map((item, index) => {
              return (
                <>
                  {createClassDetails(
                    index + 1,
                    item.classDate,
                    item.classTopics
                  )}
                </>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h2" gutterBottom>
        {REQUIRED_MATERIALS_LABEL}
      </Typography>
      <Stack direction="column">
        <BookInfo>
          <img
            src="https://assets.crosslifebiblechurch.org/healthy-church-member.jpg"
            alt="What is a Healthy Church Member"
            width={150}
          />
          <PurchaseInfo>
            Purchase{' '}
            <a href="https://www.amazon.com/What-Healthy-Church-Member-Marks/dp/1433502127">
              here{' '}
            </a>
            or borrow from our church library. More info about our church
            library can be found on Discord.
          </PurchaseInfo>
        </BookInfo>
        <BookInfo>
          <img
            src="https://assets.crosslifebiblechurch.org/church-discipline.jpg"
            alt="Church Discipline"
            width={150}
          />
          <PurchaseInfo>
            Purchase{' '}
            <a href="https://www.amazon.com/Church-Discipline-Protects-Building-Churches/dp/1433532336">
              here{' '}
            </a>
            or borrow from our church library. More info about our church
            library can be found on Discord.
          </PurchaseInfo>
        </BookInfo>
      </Stack>
    </>
  )
}

export default ChurchMembership
