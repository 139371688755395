import { FC } from 'react'
import { PlanningMailToLink } from '../common/MailToLink'
import Paragraph from '../common/Paragraph'

const AdultSmallGroups: FC = () => {
  return (
    <>
      <Paragraph>
        Adult small groups meet throughout the school year, with each group
        studying a different book of the New Testament together. Groups
        typically meet weekly or biweekly, broken out into groups of men or
        women, with 4-6 members in each group.
      </Paragraph>
      <Paragraph>
        Most groups are only for members or those who are in the process of
        becoming members, with sign ups once a year in the late summer (closed
        for 2024-2025).
      </Paragraph>
      <Paragraph>
        We do offer one small group that is open to all that meets every other
        Thursday evening at church. If interested in checking it out, please
        contact <PlanningMailToLink />.
      </Paragraph>
    </>
  )
}

export default AdultSmallGroups
