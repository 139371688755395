/**
 * Church information
 */
export const CHURCH_LOCATION = '1 Tarocco, Irvine, CA 92618'
export const CHURCH_SERVICE_TIME = 'Sunday at 4PM'

/**
 * Emails
 */
export const PLANNING_EMAIL = 'planning@crosslifebiblechurch.org'
export const CHILDREN_MINISTRY_EMAIL = 'cm@crosslifebiblechurch.org'
export const CCF_EMAIL = 'ccf@crosslifebiblechurch.org'
export const ADMIN_EMAIL = 'admin@crosslifebiblechurch.org'

/**
 * Misc
 */
export const BUG_REPORT = 'Report Bugs'
