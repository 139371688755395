/* eslint-disable react/no-danger */
import { Typography } from '@mui/material'
import { FC } from 'react'
import InfoBox from '../common/InfoBox'
import Paragraph from '../common/Paragraph'
import {
  WELCOME_TEXT_1,
  WELCOME_TEXT_2,
  WELCOME_TEXT_3,
  WELCOME_TEXT_4,
} from './strings'

const WelcomeSection: FC = () => {
  return (
    <>
      <Paragraph>{WELCOME_TEXT_1}</Paragraph>
      <InfoBox variant="secondary">
        <Typography variant="h2" align="center" gutterBottom>
          {WELCOME_TEXT_2}
        </Typography>
        <Paragraph>{WELCOME_TEXT_3}</Paragraph>
        <Paragraph>{WELCOME_TEXT_4}</Paragraph>
      </InfoBox>
    </>
  )
}

export default WelcomeSection
